import './assets/css/title.css'

function Title(props) {
    return (
        <div className="title-container">
            <div className="title-wrapper">
                <div className="title">{props.text}</div>
            </div>
        </div>
    )
}

export default Title;