import './assets/css/event-card.css'


function EventCard(props) {
    return (
        <div className="event-card-container">
            <div className="event-card-cover"><img src={props.img} alt="teszt" /></div>
            <div className="event-card-detail-container">
                <div className="event-card-detail-date">
                    <div className="event-card-detail-date-month">{props.month}</div>
                    <div className="event-card-detail-date-day">{props.day}</div>
                </div>

                <div className="event-card-detail-party">
                    <div className="event-card-detail-party-clock">{props.clock}</div>
                    <div className="event-card-detail-party-what">{props.what}</div>
                </div>
            </div>
            <div className="event-card-ticket-container">
                <div className="event-card-ticket">Tickets</div>
            </div>
        </div>
    )
}

export default EventCard;