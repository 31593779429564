import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import './assets/css/font-import.css'
import Header from './Header';
import Logobg from './Logobg';
import Title from './Title';
import Event from './Event';
import LineAndBreak from './LineAndBreak';
import Shop from './Shop';
import Footer from './Footer'
import reportWebVitals from './reportWebVitals';

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <Logobg />
    <Header />
    <Title text="Events" />
    <Event />
    <LineAndBreak />
    <Title text="Shop" />
    <Shop />
    <Footer />
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
