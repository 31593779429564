import './Header.css';
import arzilogo from './assets/img/arzilogo.png';

function Header() {
  return (
    <div className="header-container">
      <div className="header-wrapper">
        <div className="header-desktop-menu">
          <div className="desktop-menu-elem">
            <img src={arzilogo} alt="Arzenál Official" />
          </div>
          <div className="desktop-menu-elem">Events</div>
          <div className="desktop-menu-elem">Shop</div>
          <div className="desktop-menu-elem">Contact</div>
          <div className="desktop-menu-elem">Rules</div>
          <div className="desktop-menu-elem  ticket">Tickets</div>
        </div>
        <div className="header-mobile-menu">
          <div className="mobile-menu-icon">&#9776;</div>
        </div>
      </div>
    </div>
  );
}

export default Header;
