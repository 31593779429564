import './assets/css/shop.css'
import ShopCard from './ShopCard';
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';
import product from './assets/img/hoodie.png'
import producthover from './assets/img/hoodie-hover.png'

function Shop(props) {
    return (
        <div className="shop-container">
            <div className="shop-wrapper">
                <ShopCard img={product} imghover={producthover} />
                <ShopCard img={product} imghover={producthover} />
                <ShopCard img={product} imghover={producthover} />
                <ShopCard img={product} imghover={producthover} />
                <ShopCard img={product} imghover={producthover} />
            </div>
        </div>
    )
}

export default Shop;