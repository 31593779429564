import './assets/css/lineandbreak.css';

function LineAndBreak() {
  return (
    <div className="line-container">
        <div className="line"></div>
    </div>
  );
}

export default LineAndBreak;
