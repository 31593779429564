import './assets/css/footer.css';

function Footer() {
    return (
        <div className="footer flex center-flex">
            <div></div>
            <div>
                <div className="montreg">Follow us on</div>
                <div>ikonok</div>
            </div>
            <div className="footer-documents-container flex">
                {/*<div className="footer-documents flex montreg">
                    <a href="">Tájékoztatás adatkezelésről</a>
                    <div className="montreg footer-doc-divider">|</div>
                    <a href="">Panaszkezelési szabályzat</a>
                    <div className="montreg footer-doc-divider">|</div>
                    <a href="">Adatkezelési szabályzat</a>
                    <div className="montreg footer-doc-divider">|</div>
                    <a href="">Magatartási kódex</a>
                    <div className="montreg footer-doc-divider">|</div>
                    <a href="">Általános szerződési feltételek</a>
                    <div className="montreg footer-doc-divider">|</div>
                    <a href="">Kamera szabályzat</a>
                    <div className="montreg footer-doc-divider">|</div>
                    <a href="">Információbiztonsági szabályzat</a>
    </div>*/}
                <div className="footer-documents flex montreg">
                    <a href="assets\content\Arzenál_házirend_updated.pdf" target="_blank">Házirend</a>
                    <div className="montreg footer-doc-divider">|</div>

                    <a href="assets\content\biztonsági_terv_5sz_melléklet_kiürítésszámítás.pdf" target="_blank">Biztonsági terv</a>
                    <div className="montreg footer-doc-divider">|</div>

                    <a href="assets/content/biztosítási_terv.pdf" target="_blank">Biztosítási terv</a>
                    <div className="montreg footer-doc-divider">|</div>

                    <a href="assets/content/kameraszabályzat_Arzenal_Club_Kft.pdf" target="_blank">Kameraszabályzat</a>
                    <div className="montreg footer-doc-divider">|</div>

                    <a href="assets/content/Rendezvénytartási_3_melléklet_biztonsági_terv.pdf" target="_blank">Rendezvénytartási melléklet</a>
                </div>
                <div className="montreg">Copyright 2023. All rights reserved. Arzenal Budapest</div>
            </div>
        </div>
    );
}

export default Footer;