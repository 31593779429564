import './assets/css/event.css'
import EventCard from './EventCard'
import EventCoverOne from './assets/img/event-header.jpg'
import EventCoverTwo from './assets/img/event-header2.jpg.png'
import EventCoverThree from './assets/img/event-header3.png'
import { Swiper, SwiperSlide, useSwiper } from 'swiper/react';
import { Navigation, Pagination, Scrollbar, A11y } from 'swiper/modules';
import 'swiper/css';

function Event(props) {
    const swiper = useSwiper();
    return (

        <div className="event-container">
            <div className="arrow-cont">
                <div className="arrow-left" ></div>
            </div>
            <div className="event-wrapper">
                <Swiper
                    modules={[Navigation, Pagination, Scrollbar, A11y]}
                    spaceBetween={150}
                    slidesPerView={3}
                    navigation={{
                        nextEl: '.arrow-right',
                        prevEl: '.arrow-left'
                    }}
                >
                    <SwiperSlide>
                        <EventCard img={EventCoverOne} month="Jul" day="20" clock="22:00" what="Teszt party style what" />
                    </SwiperSlide>
                    <SwiperSlide>
                        <EventCard img={EventCoverTwo} month="Aug" day="20" clock="22:00" what="Teszt party style what" />
                    </SwiperSlide>
                    <SwiperSlide>
                        <EventCard img={EventCoverThree} month="Sep" day="20" clock="22:00" what="Teszt party style what" />
                    </SwiperSlide>
                    <SwiperSlide>
                        <EventCard img={EventCoverOne} month="Oct" day="20" clock="22:00" what="Teszt party style what" />
                    </SwiperSlide>
                </Swiper>
            </div>
            <div className="arrow-cont">
                <div className="arrow-right" ></div>
            </div>
        </div>
    )
}

export default Event;