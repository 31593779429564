import './assets/css/shop-card.css'


function ShopCard(props) {
    return (
        <div className="shop-card-container">

            <div className="shop-card-cover">
                <img
                src={props.img}
                onMouseOver={e => (e.currentTarget.src = props.imghover)}
                onMouseOut={e => (e.currentTarget.src = props.img)}
                alt="" />
            </div>

            <div className="event-card-detail-container">

            </div>
            <div className="shop-card-buy-container">
                <div className="shop-card-buy">Buy Now</div>
            </div>
        </div>
    )
}

export default ShopCard;